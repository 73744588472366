<template>
  <div class="flex items-center sm:space-x-4">
    <div class="hidden items-center space-x-2 text-xs font-bold text-supplement lg:flex">
      <span>{{ $t("share") }}</span>
      <Icon icon="long-arrow-right" class="text-sm text-gray-300" />
    </div>

    <div class="space-y-3 text-sm lg:flex lg:space-x-2 lg:space-y-0">
      <div class="flex items-center space-x-4" @click="openShareModal('facebook')">
        <Button icon="facebook" icon-prefix="fab" :ui="{ base: 'h-10 w-10 p-0 text-lg bg-blue-100 text-blue-500 hover:bg-blue-500 shadow-none' }" />
        <span class="text-primary hover:text-primary-hover lg:hidden">Share on Facebook</span>
      </div>

      <div class="flex items-center space-x-4" @click="openShareModal('linkedin')">
        <Button icon="linkedin" icon-prefix="fab" :ui="{ base: 'h-10 w-10 p-0 text-lg bg-[#dcedfd] text-[#0a66c2] hover:bg-[#0a66c2] shadow-none' }" />
        <span class="text-primary hover:text-primary-hover lg:hidden">Share on LinkedIn</span>
      </div>

      <div class="flex items-center space-x-4" @click="openShareModal('whatsapp')">
        <Button
          icon="whatsapp"
          icon-prefix="fab"
          :ui="{ base: 'h-10 w-10 p-0 text-lg bg-[#b1f1c9] text-[#1b994a] hover:bg-[#25d366] hover:text-white shadow-none' }"
        />
        <span class="text-primary hover:text-primary-hover lg:hidden">Share on WhatsApp</span>
      </div>

      <div class="flex items-center space-x-4" @click="openShareModal('email')">
        <Button icon="envelope" icon-prefix="fas" :ui="{ base: 'h-10 w-10 p-0 text-lg bg-purple-100 text-purple-500 hover:bg-purple-500 shadow-none' }" />
        <span class="text-primary hover:text-primary-hover lg:hidden">Share by Email</span>
      </div>

      <div class="flex items-center space-x-4" @click="openShareModal('qrcode')">
        <Button icon="qrcode" icon-prefix="fas" :ui="{ base: 'h-10 w-10 p-0 text-lg bg-teal-100 text-teal-500 hover:bg-teal-500 shadow-none' }" />
        <span class="text-primary hover:text-primary-hover lg:hidden">Get QR Code</span>
      </div>

      <div class="flex items-center space-x-4" @click="openShareModal('link')">
        <Button icon="link" icon-prefix="fas" :ui="{ base: 'h-10 w-10 p-0 text-lg bg-amber-100 text-amber-500 hover:bg-amber-500 shadow-none' }" />
        <span class="text-primary hover:text-primary-hover lg:hidden">Get Link</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ShareOption } from "~/types";

const props = withDefaults(
  defineProps<{
    label?: string;
    collectionName: string;
    landingPageUrl: string;
    partnerName: string;
    socialShareText: string | null;
  }>(),
  {
    label: "Share content",
  }
);

// Share Modal
const { openModal } = useModal();
const ShareModal = resolveComponent("LazyShareModal");

const openShareModal = (activeOption: ShareOption) =>
  openModal(ShareModal, {
    landingPageUrl: props.landingPageUrl,
    partnerName: props.partnerName,
    socialShareText: props.socialShareText,
    activeOption,
  });
</script>
