<template>
  <span class="grid h-5 w-5 place-items-center text-lg" :class="color">
    <Icon :icon="icon.icon" :prefix="icon.prefix" />
  </span>
</template>

<script setup lang="ts">
import type { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import type { ShareOption } from "~/types";

const props = defineProps<{
  type: ShareOption;
}>();

const iconMapping: Record<ShareOption, { icon: IconName; prefix: IconPrefix }> = {
  email: { icon: "envelope", prefix: "fas" },
  facebook: { icon: "facebook", prefix: "fab" },
  link: { icon: "link", prefix: "fas" },
  linkedin: { icon: "linkedin", prefix: "fab" },
  qrcode: { icon: "qrcode", prefix: "fas" },
  whatsapp: { icon: "whatsapp", prefix: "fab" },
};

const typeColorMapping = {
  email: "text-purple-500",
  facebook: "text-blue-500",
  link: "text-amber-500",
  linkedin: "text-[#0a66c2]",
  qrcode: "text-teal-500",
  whatsapp: "text-[#1b994a]",
};

const icon = computed(() => iconMapping[props.type]);
const color = computed(() => typeColorMapping[props.type]);
</script>
