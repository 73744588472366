export type EventSourceStatus = "idle" | "pending" | "success" | "error";

export const useEventSource = (url: MaybeRef<string>, immediate: boolean = true, withCredentials = true) => {
  const status = ref<EventSourceStatus>("idle");
  const data = ref<string>("");
  const error = ref<Event | null>(null);

  let eventSource: EventSource | null = null;

  const connect = () => {
    if (eventSource) return; // Prevent multiple connections

    eventSource = new EventSource(isRef(url) ? url.value : url, { withCredentials });
    status.value = "pending";

    eventSource.addEventListener("message", (event) => {
      data.value = data.value + event.data;
    });

    eventSource.addEventListener("end", (event) => {
      status.value = "success";
      disconnect();
    });

    eventSource.addEventListener("error", (err) => {
      error.value = err;
      status.value = "error";
      disconnect(); // Automatically disconnect on error
    });
  };

  const disconnect = () => {
    if (eventSource) {
      eventSource.close();
      eventSource = null;
    }
  };

  const refresh = () => {
    disconnect();
    clear();
    connect();
  };

  const clear = () => {
    data.value = "";
    error.value = null;
    status.value = "idle";
    disconnect();
  };

  if (isRef(url)) {
    const unWatchUrl = watch(url, () => refresh());
    onUnmounted(unWatchUrl);
  }

  if (immediate) {
    onMounted(connect);
  }

  onUnmounted(disconnect);

  return {
    data,
    status,
    error,
    refresh,
    clear,
  };
};
