<template>
  <Modal no-padding hide-footer class="share-modal">
    <div>
      <div class="flex items-center justify-between space-x-6 p-5 pb-0">
        <div>
          <InputLabel>Share:</InputLabel>
          <SelectInput v-model="activeShareOption" :options="shareOptions" name="share_option" class="mt-1.5" @update:modelValue="(value) => loadOption(value)">
            <template #label-selected>
              <div class="flex space-x-1.5">
                <ShareOptionIcon :type="activeShareOption" />
                <span>{{ label[activeShareOption] }}</span>
              </div>
            </template>

            <template #item-leading="{ item }">
              <ShareOptionIcon :type="item.value" />
            </template>
          </SelectInput>
        </div>
        <div>
          <div v-show="['facebook', 'linkedin', 'email'].includes(activeShareOption)">
            <InputLabel :ui="{ label: 'text-right' }">Language:</InputLabel>
            <SelectInput v-model="activeLanguage" :options="shareLanguages" name="share_language" position="right" class="mt-1.5" />
          </div>
        </div>
      </div>

      <div class="p-8">
        <div v-if="isLoaded('facebook')" v-show="activeShareOption === 'facebook'">
          <div class="flex items-center space-x-5 rounded bg-primary-50 p-6 text-sm text-primary-500">
            <Icon icon="robot" prefix="fal" class="text-3xl" />
            <p>Our AI Assistant is writing something just for you! Simply copy, paste, edit and share.</p>
          </div>

          <ChatAi
            v-model="facebookPost"
            :endpoint="`ai/extended-content-experiences/${hashid}/${activeLanguage}/facebook-post`"
            :language="activeLanguage"
            :partner-name="partnerName"
            :initial-message="socialShareText"
            class="my-6 shadow"
            :replace="{ '[link]': landingPageUrl }"
            @status="onFacebookPostStatusChange"
          />

          <div class="sm:flex sm:items-center sm:justify-end sm:space-x-6">
            <Button
              append-icon="share"
              append-icon-prefix="fas"
              color="pink"
              @click="copyAndShareFacebookPost"
              :disabled="['pending', 'error'].includes(facebookPostStatus)"
            >
              <span>Copy text &amp; create post</span>
            </Button>
          </div>
        </div>

        <div v-if="isLoaded('linkedin')" v-show="activeShareOption === 'linkedin'">
          <div class="flex items-center space-x-5 rounded bg-primary-50 p-6 text-sm text-primary-500">
            <Icon icon="robot" prefix="fal" class="text-3xl" />
            <p>Our AI Assistant is writing something just for you! Simply copy, paste, edit and share.</p>
          </div>

          <ChatAi
            v-model="linkedinPost"
            :endpoint="`ai/extended-content-experiences/${hashid}/${activeLanguage}/linkedin-post`"
            :language="activeLanguage"
            :partner-name="partnerName"
            :initial-message="socialShareText"
            class="my-6 shadow"
            :replace="{ '[link]': landingPageUrl }"
            @status="onLinkedinPostStatusChange"
          />

          <div class="sm:flex sm:items-center sm:justify-end sm:space-x-6">
            <Button
              append-icon="share"
              append-icon-prefix="fas"
              color="pink"
              @click="copyAndShareLinkedinPost"
              :disabled="['pending', 'error'].includes(linkedinPosStatus)"
            >
              <span>Copy text &amp; create post</span>
            </Button>
          </div>
        </div>

        <div v-if="isLoaded('whatsapp')" v-show="activeShareOption === 'whatsapp'">
          <div>
            <Button as="a" :href="`https://wa.me/?text=${landingPageUrl}`" target="_blank" append-icon="share" append-icon-prefix="fas" color="pink"
              >Share via WhatsApp</Button
            >
          </div>
        </div>

        <div v-if="isLoaded('email')" v-show="activeShareOption === 'email'">
          <div class="flex items-center space-x-5 rounded bg-primary-50 p-6 text-sm text-primary-500">
            <Icon icon="robot" prefix="fal" class="text-3xl" />
            <p>Our AI Assistant is writing something just for you! Simply copy, paste, edit and share.</p>
          </div>

          <ChatAi
            v-model="emailCopy"
            :endpoint="`ai/extended-content-experiences/${hashid}/${activeLanguage}/personal-email`"
            :language="activeLanguage"
            class="my-6 shadow"
            :replace="{ '[link]': landingPageUrl }"
            @status="onEmailStatusChange"
          />

          <div class="flex items-center justify-end space-x-6">
            <div class="shrink-0">
              <Button
                :prepend-icon="emailCopied ? 'check' : 'copy'"
                prepend-icon-prefix="fas"
                color="pink"
                :disabled="['pending', 'error'].includes(emailStatus)"
                @click="copyDecodedEmail"
              >
                {{ emailCopied ? "Copied!" : "Copy email" }}
              </Button>
            </div>
          </div>
        </div>

        <div v-if="isLoaded('qrcode')" v-show="activeShareOption === 'qrcode'">
          <div class="md:flex">
            <div class="relative grid h-[160px] w-[160px] flex-shrink-0 place-items-center bg-gray-50">
              <Icon icon="spinner-third" prefix="fad" spin class="text-2xl" />
              <img :src="`${backendUrl}/shared-collection-personalisations/${hashid}/qr`" height="160" width="160" class="absolute inset-0" />
            </div>
            <div class="space-y-6 md:ml-10">
              <p class="mt-6 md:mt-0">Download the code or screenshot it for even faster sharing.</p>
              <p>
                <Button
                  as="a"
                  :href="`${`${backendUrl}/shared-collection-personalisations/${hashid}/qr`}?download`"
                  prepend-icon="cloud-arrow-down"
                  color="brand"
                  >Download QR code</Button
                >
              </p>
            </div>
          </div>
        </div>

        <div v-if="isLoaded('link')" v-show="activeShareOption === 'link'">
          <div class="mx-auto max-w-lg space-y-4">
            <p class="text-center font-semibold">The possibilities are limitless...</p>
            <input type="text" :value="landingPageUrl" class="w-full rounded-md border px-4 py-3" readonly />
            <UseClipboard v-slot="{ copy, copied }" :source="landingPageUrl" :copied-during="2000">
              <Button :prepend-icon="copied ? 'check' : 'copy'" prepend-icon-prefix="fas" color="pink" full-width @click="copy()">
                <span v-if="copied">Copied!</span>
                <span v-else>Copy link to your content experience</span>
              </Button>
            </UseClipboard>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { useClipboard } from "@vueuse/core";
import { UseClipboard } from "@vueuse/components";
import type { Alpha2LanguageCode, ShareOption } from "~/types";
import type { EventSourceStatus } from "~/composables/useEventSource";

export interface ShareModalProps {
  landingPageUrl: string;
  partnerName: string;
  socialShareText: string;
  activeOption?: ShareOption;
}

const props = withDefaults(defineProps<ShareModalProps>(), {
  activeOption: "facebook",
});

const hashid = getVersionHashidFromUrl();
const { backendUrl } = useRuntimeConfig().public;

// Share Options
const label: Record<ShareOption, string> = {
  email: "Email",
  facebook: "Facebook",
  link: "Link",
  linkedin: "LinkedIn",
  qrcode: "QR Code",
  whatsapp: "WhatsApp",
};

const shareOptions: { label: string; value: ShareOption }[] = [
  { label: "Facebook", value: "facebook" },
  { label: "LinkedIn", value: "linkedin" },
  { label: "WhatsApp", value: "whatsapp" },
  { label: "Email", value: "email" },
  { label: "QR Code", value: "qrcode" },
  { label: "Link", value: "link" },
];

const shareLanguages: { label: string; value: Alpha2LanguageCode }[] = [
  { label: "Arabic", value: "ar" },
  { label: "Danish", value: "da" },
  { label: "Dutch", value: "nl" },
  { label: "English", value: "en" },
  { label: "French", value: "fr" },
  { label: "German", value: "de" },
  { label: "Hebrew", value: "he" },
  { label: "Italian", value: "it" },
  { label: "Japanese", value: "ja" },
  { label: "Mandarin Chinese", value: "zh" },
  { label: "Portuguese", value: "pt" },
  { label: "Spanish", value: "es" },
  { label: "Swedish", value: "sv" },
];

const loadedOptions = ref([props.activeOption]);

const loadOption = (option: ShareOption) => {
  if (!loadedOptions.value.includes(option)) {
    loadedOptions.value.push(option);
  }
};

const isLoaded = (option: ShareOption) => loadedOptions.value.includes(option);

const activeShareOption = ref(props.activeOption);
const activeLanguage = ref<Alpha2LanguageCode>("en");

// Facebook post
const facebookPost = ref("");
const { copy: copyFacebookPost } = useClipboard({ source: facebookPost });

const facebookPostStatus = ref<EventSourceStatus>("idle");
const onFacebookPostStatusChange = (status: EventSourceStatus) => (facebookPostStatus.value = status);

const copyAndShareFacebookPost = async () => {
  await copyFacebookPost();
  window.open(`https://www.facebook.com/share.php?u=${props.landingPageUrl}`, "_blank");
};

// LinkedIn post
const linkedinPost = ref("");
const { copy: copyLinkedinPost } = useClipboard({ source: linkedinPost });

const linkedinPosStatus = ref<EventSourceStatus>("idle");
const onLinkedinPostStatusChange = (status: EventSourceStatus) => (linkedinPosStatus.value = status);

const copyAndShareLinkedinPost = async () => {
  await copyLinkedinPost();
  window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${props.landingPageUrl}`, "_blank");
};

// Email
const emailStatus = ref<EventSourceStatus>("idle");
const emailCopy = ref("");
const emailCopied = ref(false);
const onEmailStatusChange = (status: EventSourceStatus) => (emailStatus.value = status);

const copyDecodedEmail = () => {
  const { copy } = useClipboard();
  copy(decodeURIComponent(emailCopy.value));
  emailCopied.value = true;
  setTimeout(() => (emailCopied.value = false), 2000);
};
</script>

<style lang="postcss">
.share-modal .modal__close {
  @apply right-0 top-0 -mr-3 -mt-3 h-6 w-6 rounded-full bg-white shadow hover:text-slate-900;
}
</style>
